
import { Vue, Component, Prop } from "vue-property-decorator";
import { mdiArrowLeft } from "@mdi/js";
import { addAnalyticsEvent } from "@/plugins/firebase";
import { Location } from "vue-router";

@Component
export default class BackButton extends Vue {
    @Prop({ required: false }) route?: Location;
    @Prop({ required: false, type: Boolean, default: false }) block!: boolean;
    backIcon: string = mdiArrowLeft;
    goBack() {
        addAnalyticsEvent("back_button_clicked");
        if (this.route) {
            this.$router.push(this.route);
            return;
        }
        this.$router.back();
    }
}
